@import '../scss/lib';

.canvas {
  @include fill-space;
  position: absolute !important;
  height: max(55svh, 400px) !important;
  pointer-events: none;

  @include media-up(lg) {
    height: 80svh !important;
    pointer-events: auto;
  }
}

.background {
  @include hide-down(lg);
  @include fill-space;
  height: 100svh;
  object-fit: cover;
  object-position: top;
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 60%,
    rgba(0, 0, 0, 0)
  );
}
