@import '../scss/lib';

.container {
  max-width: 1280px;
  padding: 0 20px;
  margin: 0 auto;

  @include media-up(lg) {
    padding: 0 60px;
  }
}

.hero {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: 100svh;
}

.title {
  @include visually-hidden;
}
