@import '../scss/lib';

.icon {
  --thickness: 2px;
  --size: 20px;
  $thickness: var(--thickness);
  $size: var(--size);
  @include size($size);
  position: relative;

  &::before,
  &::after {
    @include center-self;
    position: absolute;
    content: '';
    background: $black;
  }

  &::before {
    width: $thickness;
    height: 100%;
  }

  &::after {
    width: 100%;
    height: $thickness;
  }
}

.iconWhite {
  &::before,
  &::after {
    background: $white;
  }
}

.iconRotated {
  transform: rotate(45deg);
}
