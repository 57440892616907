@import '../scss/lib';

.creators {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 188px;

  @include media-up(lg) {
    margin-bottom: 276px;
  }
}

.title {
  margin-bottom: 44px;
  font-size: 12px;
  font-style: italic;
  font-weight: $weight-regular;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.2em;

  @include media-up(lg) {
    margin-bottom: 64px;
    font-size: 14px;
  }
}

.creatorCards {
  display: grid;
  grid-template-columns: 1fr;
  gap: 56px;

  @include media-up(lg) {
    grid-template-columns: repeat(4, 1fr);
    gap: 64px 44px;
    margin: 0 12px;
  }
}

.creatorCard {
  display: flex;
  flex-direction: column;
  align-items: center;
}

$media-width-sm: 266px;
$media-width-md: 420px;
$media-width-lg: 250px;

.mediaContainer {
  @include size($media-width-sm);
  @include center-content;

  @include media-up(md) {
    @include size($media-width-md);
  }

  @include media-up(lg) {
    @include size($media-width-lg);
  }
}

.creatorName {
  display: block;
  max-width: $media-width-sm;
  margin-top: 25px;
  font-size: 21px;
  font-weight: $weight-regular;
  text-align: center;

  @include media-up(md) {
    max-width: $media-width-md;
  }

  @include media-up(lg) {
    max-width: none;
    margin-top: 33px;
  }
}

.paginationBtn {
  margin: 0 auto;
  margin-top: 50px;
}
