@import '../scss/lib';

@mixin border-radius() {
  border-radius: 3.2%;

  @include media-up(lg) {
    border-radius: 15px;
  }
}

.imageWrapper {
  @include border-radius;
  position: relative;
  border: 1px solid #404040;

  img {
    @include size(inherit);
    @include responsive-img;
    @include border-radius;
    object-fit: cover;
  }
}

.imageFrame {
  position: absolute;
  top: 0;
  height: 100%;
  pointer-events: none;
}

.imageFrameLeft {
  left: -1px;
}

.imageFrameRight {
  right: -2px;

  @include media-up(lg) {
    right: -1px;
  }
}

.imageContainer {
  height: 100%;
  padding: 8px;
}

.imageContainerChild {
  @include border-radius;
  height: 100%;
  overflow: hidden;
}
