@font-face {
  font-family: 'Hanken Grotesk';
  font-weight: 900;
  src: url('/fonts/HankenGrotesk-Black.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Hanken Grotesk';
  font-weight: 700;
  src: url('/fonts/HankenGrotesk-Bold.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Hanken Grotesk';
  font-weight: 400;
  src: url('/fonts/HankenGrotesk-Regular.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Hanken Grotesk';
  font-style: italic;
  font-weight: 500;
  src: url('/fonts/HankenGrotesk-MediumItalic.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Hanken Grotesk';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/HankenGrotesk-Italic.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Hanken Grotesk';
  font-weight: 300;
  src: url('/fonts/HankenGrotesk-Light.ttf');
  font-display: swap;
}
