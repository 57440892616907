@import '../scss/lib';

.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 19px;
  margin-top: 149px;
  margin-bottom: 120px;
  font-size: 14px;
  font-weight: $weight-regular;
  line-height: 15px;
  color: $black;
  background: $white;
  border-radius: 10px;

  @include media-up(md) {
    margin-bottom: 37px;
  }

  @include media-up(lg) {
    flex-direction: row;
    align-items: center;
    padding: 16px 41px;
    font-size: 15px;
  }
}

.linksList {
  display: flex;
  margin-top: 20px;
  list-style: none;

  @include media-up(lg) {
    margin-top: 0;
  }
}

.linksListItem {
  display: flex;
  align-items: center;
  margin-left: auto;
  text-align: center;

  &:not(:last-child)::after {
    display: block;
    width: 1px;
    height: 27px;
    margin: 0 14px;
    content: '';
    background-color: #cccccc;
  }
}
