@import '../scss/lib';

.introduction {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 393px;
  text-align: justify;
  pointer-events: none;

  @include media-up(md) {
    flex-direction: row;
    margin-top: auto;
    margin-bottom: 98px;
  }
}

.hidden {
  opacity: 0;
}

.leftColumn {
  max-width: 588px;
  margin-bottom: 31px;
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;

  @include media-up(sm) {
    font-size: 22px;
    line-height: 32px;
  }

  @include media-up(md) {
    width: 100%;
  }

  @include media-up(lg) {
    margin-bottom: 0;
    font-size: 30px;
    line-height: 46px;
  }

  :global(.lineParent) {
    overflow: hidden;
  }
}

.gradientText {
  font-style: italic;
  background: $orange-gradient;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.rightColumn {
  position: relative;
  max-width: 434px;
  margin-left: 32px;
  font-size: 13px;
  font-weight: $weight-light;
  line-height: 21px;

  @include media-up(md) {
    width: 100%;
  }

  @include media-up(lg) {
    top: 9px;
    margin-inline: 0 34px;
    font-size: 16px;
    line-height: 25px;
  }
}

.moreBtn {
  margin-top: 20px;
  font-size: 11px;
  color: $white;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  pointer-events: auto;

  @include media-up(lg) {
    position: absolute;
    bottom: -31px;
    left: 0;
    margin-top: 0;
    font-size: 12px;
  }
}

.moreBtnText {
  position: relative;
}

.moreBtnUnderline {
  position: absolute;
  bottom: -5px;
  width: 100%;
  height: 1px;
  background: $white;
}
