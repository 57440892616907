@use 'sass:map';

// Screens
$media-breakpoints: (
  xxs: 0,
  xs: 360px,
  sm: 375px,
  md: 768px,
  lg: 1280px,
  xl: 1440px,
  xxl: 1920px
);

$xxs: map.get($media-breakpoints, xxs);
$xs: map.get($media-breakpoints, xs);
$sm: map.get($media-breakpoints, sm);
$md: map.get($media-breakpoints, md);
$lg: map.get($media-breakpoints, lg);
$xl: map.get($media-breakpoints, xl);
$xxl: map.get($media-breakpoints, xxl);

// Colors
$white: #ffffff;
$black: #000000;

// Layers
$z-layers: (
  header: 10,
  lightbox: 20
);

// Fonts
$font-primary: 'Hanken Grotesk', sans-serif;

$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-bold: 700;
$weight-black: 900;

// Transitions
$ease-out-quad: cubic-bezier(0.5, 1, 0.89, 1);
$ease-out-cubic: cubic-bezier(0.33, 1, 0.68, 1);
$ease-in-out-circ: cubic-bezier(0.85, 0, 0.15, 1);
$ease-out-steep: cubic-bezier(0.12, 1, 0.45, 0.9);

$transition: 0.2s $ease-out-quad;
$transition-slow: 0.5s $ease-out-quad;

// Other
$orange-gradient: linear-gradient(90deg, #ff7342, #ff0000);
