@import '../scss/lib';

.partners {
  max-width: 1054px;
  padding: 0 25px;
  margin: 0 auto;
}

.title {
  margin-bottom: 75px;
  font-size: 14px;
  font-style: italic;
  font-weight: $weight-medium;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.logos {
  display: grid;
  grid-template-columns: auto;
  gap: 78px;
  align-items: center;
  justify-content: center;

  @include media-up(lg) {
    grid-template-columns: repeat(4, auto);
    gap: 78px 118px;
  }
}

.logo {
  margin: 0 auto;
  object-fit: contain;
}
