@import '../../scss/lib';

$image-height-sm: 222px;
$image-height-lg: 373px;

.explainer {
  --explainer-width: 335px;
  position: relative;
  display: flex;
  align-items: flex-end;
  width: var(--explainer-width);
  min-width: var(--explainer-width);
  min-height: $image-height-sm;
  overflow: clip;
  pointer-events: none;

  @include media-up(lg) {
    --explainer-width: 728px;
    min-height: $image-height-lg;
  }
}

.contentWrap {
  --content-height: 182px;
  position: relative;
  width: 80px;
  height: 50px;
  overflow: clip;
  color: $black;
  pointer-events: auto;
  background: #e8e8e8;
  border-radius: 16px;
  opacity: 0;
  transform: translateZ(0.000001px);
  animation: bg-animation 1.3s 0.8s $ease-out-steep forwards;
  will-change: width, height;

  @include media-up(lg) {
    --content-height: 311px;
  }

  @keyframes bg-animation {
    0% {
      width: 80px;
      height: 50px;
      opacity: 0;
    }

    1% {
      opacity: 1;
    }

    100% {
      width: var(--explainer-width);
      height: var(--content-height);
      opacity: 1;
    }
  }
}

.content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: var(--explainer-width);
  height: var(--content-height);
  padding: 23px 20px 30px;

  @include media-up(lg) {
    padding: 61px 20px 46px;
  }
}

.image {
  position: absolute;
  right: -12px;
  bottom: 0;
  z-index: 1;
  width: 204px;
  height: $image-height-sm;
  object-fit: contain;
  opacity: 0;
  transform: translateY(42%);
  animation: image-animation 1.3s 0.8s $ease-out-steep forwards;

  @include media-up(lg) {
    right: 0;
    left: -8px;
    width: 342px;
    height: $image-height-lg;
  }

  @keyframes image-animation {
    0% {
      opacity: 0;
      transform: translateY(42%);
    }

    60% {
      opacity: 1;
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.header {
  width: 168px;
  margin-bottom: 12px;
  font-size: 21px;
  font-weight: $weight-bold;
  line-height: 22px;

  @include media-up(lg) {
    width: 347px;
    margin-bottom: 23px;
    margin-left: 324px;
    font-size: 42px;
    line-height: 41px;
  }
}

.description {
  width: 141px;
  font-size: 15px;
  line-height: 17px;

  @include media-up(lg) {
    width: 337px;
    margin-left: 324px;
    font-size: 23px;
    line-height: 29px;
  }
}

.closeBtn {
  position: absolute;
  top: 9px;
  right: 9px;
  display: flex;
  align-items: center;

  @include media-up(lg) {
    top: 17px;
    right: 17px;
  }
}

.closeBtnText {
  @include hide-down(lg);
  display: block;
  margin-right: 10px;
  font-size: 14px;
  font-weight: $weight-black;
  color: $black;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.closeBtnIcon {
  animation: rotate 1.3s 1.4s $ease-out-steep forwards;

  @keyframes rotate {
    0% {
      rotate: 90deg;
    }

    100% {
      rotate: 0;
    }
  }
}
