@import 'lib';
@import '~ress';

*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  font-family: $font-primary;
  font-weight: $weight-regular;
  color: $white;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #101010;
}

body {
  overflow-x: hidden;
  background: linear-gradient(45deg, #080808, #191919) fixed;

  // Fixed gradient
  // background-size: 200% 100%;
  // animation: gradient 4s linear infinite alternate;

  // Original gradient
  background-size: 600% 100%;
  animation: gradient 16s linear infinite;
  animation-direction: alternate;
}

.oneTrustDesktop {
  height: auto !important;
  overflow-y: auto !important;
}

a {
  color: inherit;
  text-decoration: none;
}

button,
a {
  -webkit-tap-highlight-color: transparent;
}

.desktop-only {
  @include hide-down(lg);
}

.mobile-only {
  @include hide-up(lg);
}

// TODO Remove if needed
body > iframe {
  visibility: hidden;
}

@keyframes gradient {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 100%;
  }
}

.ReactCollapse--collapse {
  transition: height $transition;
}

[id='root'] {
  min-width: 100svw;
}
