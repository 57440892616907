@import '../scss/lib';

.wrapper {
  position: relative;
  z-index: 1;
  margin-block: 104px 183px;

  @include media-up(lg) {
    margin-block: 140px 267px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 133px;

  @include media-up(lg) {
    gap: 270px;
  }
}

.duo {
  display: flex;
  flex-direction: column;
  gap: 48px;

  @include media-up(lg) {
    flex-direction: row-reverse;
    gap: 0;
    align-items: center;
    justify-content: space-between;

    &:nth-child(2n) {
      flex-direction: row;
    }
  }
}

.videoArticle {
  @include media-up(lg) {
    width: 697px;
  }
}

.media {
  margin: 0 auto;

  :global(.imageFrameRight) {
    @include media-down(md) {
      right: -1px;
    }
  }
}

.mediaContainer {
  display: flex;
  align-items: center;
  aspect-ratio: 335/194;

  @include media-up(lg) {
    aspect-ratio: 697/393;
  }
}

.mediaArticleContainer {
  aspect-ratio: 297/204;

  @include media-up(lg) {
    aspect-ratio: 407/280;
  }
}

.image {
  transition: transform $transition-slow;

  @include media-up(lg) {
    .media:hover & {
      transform: scale(1.1);
    }
  }
}

.mediaBtn {
  position: absolute;
  right: 20px;
  bottom: 20px;
  display: flex;
  gap: 7px;
  align-items: center;
  width: fit-content;
  padding: 9px 20px !important;
  color: $black;
  letter-spacing: 0.3em;
  mix-blend-mode: screen;

  @include media-up(lg) {
    right: 30px;
    bottom: 30px;
    gap: 0;
    width: 51px;
    padding: 13px !important;
    overflow: hidden;
    border-radius: 51px;
    transition: width $transition-slow;
  }

  &::after {
    @include media-up(lg) {
      @include size(51px);
      position: absolute;
      top: 0;
      right: 0;
      content: '';
      background: $white;
      border-radius: 50%;
    }
  }

  .media:hover & {
    @include media-up(lg) {
      width: 200px;
    }
  }
}

.mediaBtnArticle {
  @include media-up(lg) {
    right: 22px;
    bottom: 27px;
    width: 40px;
    padding: 10px !important;
  }

  &::after {
    @include media-up(lg) {
      @include size(40px);
    }
  }

  .media:hover & {
    @include media-up(lg) {
      width: 170px;
    }
  }
}

.mediaBtnTextWrapper {
  @include media-up(lg) {
    position: absolute;
    overflow: hidden;
  }

  &::after {
    @include media-up(lg) {
      @include size(40px);
    }
  }

  .media:hover & {
    @include media-up(lg) {
      width: 140px;
    }
  }
}

.mediaBtnTextWrapper {
  @include media-up(lg) {
    position: absolute;
    overflow: hidden;
  }
}

.mediaBtnText {
  @include media-up(lg) {
    display: block;
    margin-left: 10px;
    white-space: pre;
    transition: transform $transition-slow;
    transform: translateX(100%);
  }

  .media:hover & {
    @include media-up(lg) {
      transform: translateX(0);
    }
  }
}

.plusIcon {
  --size: 16px;
  --thickness: 1px;
  right: -8px;
  flex-shrink: 0;

  @include media-up(lg) {
    --size: 25px;
    --thickness: 2px;
    right: 0;
    z-index: 1;
    margin-left: auto;
  }

  .mediaBtnArticle & {
    @include media-up(lg) {
      --size: 18px;
    }
  }
}

.decoration {
  position: relative;
  width: 35px;
  height: 2px;
  margin-top: 27px;
  margin-bottom: 14px;
  margin-left: 5px;

  @include media-up(lg) {
    margin-top: 35px;
  }

  &::before,
  &::after {
    position: absolute;
    height: inherit;
    content: '';
    background: $white;
    border-radius: 1px;
  }

  &::before {
    left: 0;
    width: 7px;
  }

  &::after {
    right: 0;
    width: 22px;
  }
}

.descriptionVideo {
  margin-left: 6px;
  font-size: 18px;
  font-weight: $weight-regular;

  @include media-up(lg) {
    max-width: 503px;
    font-size: 27px;
  }
}

.videoLightbox {
  @include fill-space;
  position: fixed;
  z-index: z(lightbox);
  background: black;
}

.videoLightboxCloseBtn {
  position: fixed;
  top: 50px;
  right: 50px;
  z-index: z(lightbox) + 1;
  font-size: 12px;
  font-weight: $weight-black;
  color: $white;
  text-transform: uppercase;
  letter-spacing: 0.2em;

  &::after,
  &::before {
    background: $white;
  }
}

.videoLightboxCloseBtnIcon {
  --size: 52px;
  margin-inline: auto;
  margin-bottom: 10px;
}

.article {
  margin-inline: 5vw;

  @include media-up(lg) {
    width: 407px;
    margin-inline: 0;
  }
}

.descriptionArticle {
  margin-left: 6px;
  font-size: 16px;
  font-weight: $weight-regular;

  @include media-up(lg) {
    font-size: 21px;
  }
}

.paginationBtn {
  display: block;
  margin-inline: auto;
  margin-top: 50px;

  @include media-up(lg) {
    margin-top: 100px;
  }
}

[data-video-article-id] > div,
[data-video-article-id] > div * {
  position: absolute !important;
  height: 100% !important;
  max-height: none !important;
  aspect-ratio: auto !important;
  padding: 0 !important;
}

[data-video-article-id] iframe {
  z-index: 999;
}
