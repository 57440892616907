@import '../../scss/lib';

.support {
  position: relative;
  min-height: 50px;
}

.supportBtn {
  position: relative;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 15px 12px 15px 32px;
  font-size: 14px;
  font-weight: $weight-black;
  color: $black;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border-radius: 32px;

  @include media-up(md) {
    width: auto;
  }

  .buttonBg {
    @include fill-space;
    background: $orange-gradient;
    border-radius: 32px;
    opacity: 0;
    animation: background-animation 1.5s 0.2s $ease-out-steep forwards;
  }

  &,
  .innerText {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .innerText {
    position: relative;
    z-index: 1;
    opacity: 0;
    animation: text-animation 1s 0.2s $ease-out-steep forwards;
  }

  @keyframes text-animation {
    0% {
      opacity: 0;
    }

    50%,
    100% {
      opacity: 1;
    }
  }

  @keyframes background-animation {
    0% {
      opacity: 0;
      scale: 0.2;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 1;
      scale: 1;
    }
  }
}

.plusIcon {
  margin-left: 20px;
}

.content {
  position: relative;
  z-index: 1;
  padding: 23px 32px 62px;
  color: $black;
  background: $orange-gradient;
  border-radius: 25px;

  @include media-up(md) {
    width: 530px;
    padding: 43px 43px 26px;
    transition: filter $transition;
    transition-delay: 0.2s;
  }

  .supportRevealed & {
    filter: drop-shadow(0 0 4px #ff7342);
  }
}

.header {
  margin-bottom: 17px;
  font-size: 26px;
  font-weight: $weight-bold;
  line-height: 29px;

  @include media-up(lg) {
    max-width: 416px;
    font-size: 27px;
    font-weight: $weight-bold;
    line-height: 31px;
  }
}

.description {
  margin-bottom: 24px;
  font-size: 16px;

  @include media-up(lg) {
    max-width: 404px;
    margin-bottom: 32px;
    font-size: 18px;
  }
}

.partner {
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-size: 14px;
  font-weight: $weight-regular;
  line-height: 17px;

  @include media-up(lg) {
    margin-top: 29px;
    font-size: 16px;
  }
}

.partnerImage {
  width: 123px;
  aspect-ratio: 300/118;
  margin-left: 6px;

  @include media-up(lg) {
    margin-left: 20px;
  }
}

.closeBtn {
  position: absolute;
  right: 14px;
  bottom: 14px;
  display: flex;
  align-items: center;

  @include media-up(md) {
    top: 15px;
    right: auto;
    right: 15px;
    bottom: auto;
  }
}

.closeBtnText {
  display: block;
  margin-right: 31px;
  font-size: 14px;
  font-weight: $weight-black;
  text-transform: uppercase;
  letter-spacing: 0.1em;

  @include media-up(md) {
    margin-right: 14px;
  }
}
