@import '../scss/lib';

.btn {
  position: relative;
  padding: 10px 42px;
  font-size: 10px;
  font-weight: $weight-black;
  color: $black;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  transition: opacity $transition;

  &:disabled {
    opacity: 0.5;
  }

  &:not(.btnSmall) {
    @include media-up(lg) {
      padding: 16px 70px;
      font-size: 14px;
      letter-spacing: 0.1em;
    }
  }

  :global(.background) {
    @include fill-space;
    z-index: -1;
    background: $white;
    border-radius: 50px;
  }
}
