@import '../scss/lib';

.header {
  position: fixed;
  right: 0;
  left: 0;
  z-index: z(header);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  max-width: none;
  height: calc(100% - 2 * 38px);
  margin-inline: 20px;
  margin-top: 38px;
  pointer-events: none;

  @include media-up(lg) {
    height: calc(100% - 2 * 41px);
    margin-inline: 60px;
    margin-top: 41px;
  }
}

.logos {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  opacity: 0;
  animation: fade-in 0.7s 0.2s linear forwards;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

.logo {
  width: 135px;

  @include media-up(lg) {
    width: 162px;
    margin-top: 17px;
  }
}

.subLogo {
  width: 68px;

  @include media-up(lg) {
    width: 81px;
  }
}

.popups {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 13px;
  align-self: flex-end;
  justify-content: space-between;
  width: 100%;
  pointer-events: auto;

  @include media-up(md) {
    display: block;
    align-self: flex-start;
    width: auto;
  }
}

.explainer {
  @include media-up($width: 680px) {
    order: -1;
  }

  @include media-up(md) {
    position: absolute !important;
    bottom: 0;
    left: 0;
  }
}

.cookies {
  margin-left: auto;

  @include media-up(lg) {
    position: absolute !important;
    right: 0;
    bottom: 0;
  }
}

.support {
  @include media-down(lg) {
    width: 100%;
  }
}
